import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  LOAN_PRODUCT_TYPE,
  LOAN_RATE_TYPE,
} from 'apps/di-ld/src/app/core/constant/base.enum';
import { IProductShelf } from 'apps/di-ld/src/app/core/interfaces';
import dayjs from 'dayjs';

@Component({
  selector: 'web-di-ld-card-product-shelf',
  templateUrl: './card-product-shelf.component.html',
  styleUrls: ['./card-product-shelf.component.scss'],
})
export class CardProductShelfComponent implements OnInit {
  @Input() productDetail!: IProductShelf;
  @Output() onSelected = new EventEmitter();
  public lang: string;
  public paymentTermSM = 60;
  public imageHeight!: number;
  public endCountDownDay!: number;
  public LOAN_RATE_TYPE = LOAN_RATE_TYPE;
  public LOAN_PRODUCT_TYPE = LOAN_PRODUCT_TYPE;
  public interest: any = {
    enableSpecial: false,
    indexRateType: '',
    maxInterestRate: 0,
    maxSpecialRate: 0,
    minInterestRate: 0,
    minSpecialRate: 0,
    rateType: undefined,
  };

  constructor(private translocoService: TranslocoService) {
    this.lang = this.translocoService.getActiveLang();
  }

  getCountDownTime() {
    const endDate = dayjs(
      dayjs(this.productDetail.countDownEndDate).format('YYYY-MM-DD')
    );
    const currentDate = dayjs(dayjs().format('YYYY-MM-DD'));
    const dateAmount = endDate.diff(currentDate, 'day');
    this.endCountDownDay = dateAmount;
  }

  public getProductImage(type?: string) {
    switch (type) {
      case LOAN_PRODUCT_TYPE.PAYDAY:
        return 'assets/images/product-shelf/Paydåay.png';
      case LOAN_PRODUCT_TYPE.THUNGNGERN:
        return 'assets/images/product-shelf/100k.png';
      case LOAN_PRODUCT_TYPE.SMARTMONEY:
        return 'assets/images/product-shelf/smart-money.png';
      case LOAN_PRODUCT_TYPE._5PLUSTL:
        return 'assets/images/product-shelf/5plus.png';
      case LOAN_PRODUCT_TYPE._5PLUSOD:
        return 'assets/images/product-shelf/5plus.png';
      default:
        return '';
    }
  }

  public getTextLabel(isHot?: boolean, isNew?: boolean) {
    if (isNew) {
      return this.translocoService.translate(
        'loanProductShelf.productDetail.new'
      );
    } else if (isHot) {
      return this.translocoService.translate(
        'loanProductShelf.productDetail.recommend'
      );
    }

    return;
  }

  public getImageLabel(isHot?: boolean, isNew?: boolean) {
    if (isNew) {
      return '/assets/images/new-tag.svg';
    } else if (isHot) {
      return '/assets/images/hot-tag.svg';
    }
    return;
  }

  ngOnInit(): void {
    this.getCountDownTime();
    this.interest = {
      enableSpecial: this.productDetail.interest.enableSpecial,
      indexRateType: this.productDetail.interest.indexRateType,
      maxInterestRate: this.productDetail.interest.maxInterestRate as number,
      maxSpecialRate: this.productDetail.interest.maxSpecialRate as number,
      minInterestRate: this.productDetail.interest.minInterestRate as number,
      minSpecialRate: this.productDetail.interest.minSpecialRate as number,
      rateType: this.productDetail.interest.rateType,
    };
  }

  getAbsolute(value: number) {
    return Math.abs(value);
  }

  productSelected() {
    this.onSelected.emit(this.productDetail);
  }
}
