import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ERROR_TYPE } from 'apps/di-ld/src/app/core/constant/base.enum';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogErrorHandlerService } from '../../../error-handler/dialog-error-handler.service';

@Component({
  selector: 'web-di-ld-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title: string = '';
  @Input() buttonTitle: string = '';
  @Input() path: string =
    '/assets/images/foundationIllustrationGenericError.png';
  @Input() img: string = '';
  @Input() case: string | number = 1;
  @Input() moreMessage: string = '';
  @Input() moreTitle: string = '';
  @Input() maxSubmission: string = '';
  @Input() cutOffDay: number = 0;
  @Input() enableBackButton: boolean = false;
  @Output() clickEvt = new EventEmitter();
  @Output() onBack = new EventEmitter();

  subscription = new Subscription();

  constructor(
    public dialogService: DialogErrorHandlerService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    if (this.img == 'sadBird') {
      this.path =
        '/assets/images/foundation-illustration-contextual-vayu-application-fail.png';
    } else if (this.img == 'sadBirdFail') {
      this.path =
        '/assets/images/foundation-illustration-contextual-vayu-application-fail.png';
    } else if (this.img == 'thankBird') {
      this.path =
        '/assets/images/foundation-illustration-contextual-vayu-thank-you.png';
    } else if (this.img == 'callcenter') {
      this.path = '/assets/images/foundationIllustrationGenericError.png';
    }
  }

  ngOnChanges() {
    this.subscription.add(
      this.translocoService.selectTranslation().subscribe((translation) => {
        this.dialogService.errorType = ERROR_TYPE.FULLSCREEN;
        this.dialogService.errorTitle =
          translation['applyError.' + this.case + '.title'];
        this.dialogService.errorHttpStatusCode = 0;
        this.dialogService.errorLoaded.next();
      })
    );
  }

  emitClickEvt() {
    this.clickEvt.emit();
  }

  emitClickOnBack() {
    this.onBack.emit();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
