<div class="p-field p-fluid p-mb-1" id="divValidatorErrorMessage">
  <ng-content></ng-content>
  <small
    *ngIf="invalid && touched && ngControl?.control?.hasError('required')"
    id="require-help"
    class="p-error"
    >{{ msgRequired }}</small
  >
  <small
    *ngIf="
      invalid &&
      touched &&
      ngControl?.control?.hasError('email') &&
      !ngControl?.control?.hasError('required')
    "
    id="email-help"
    class="p-error"
    >{{ msgEmail }}</small
  >
  <small
    *ngIf="
      invalid &&
      touched &&
      ngControl?.control?.hasError(errorName) &&
      !ngControl?.control?.hasError('email')
    "
    id="error-help"
    class="p-error"
  >
    {{ msgError }}
  </small>

  <small
    *ngIf="checkReferralCodeForm(ngControl)"
    id="error-help"
    class="p-error"
  >
    {{ msgError }}
  </small>
  <small
    *ngIf="
      invalid &&
      ngControl?.control?.hasError('min') &&
      !ngControl?.control?.hasError(errorName)
    "
    id="require-help"
    class="p-error"
    >{{ msgMin }}</small
  >
  <small
    *ngIf="
      invalid &&
      ngControl?.control?.hasError('max') &&
      !ngControl?.control?.hasError('min')
    "
    id="require-help"
    class="p-error"
    >{{ msgMax }}</small
  >
  <small
    *ngIf="
      invalid &&
      ngControl?.control?.hasError('minlength') &&
      !ngControl?.control?.hasError('max')
    "
    id="require-help"
    class="p-error"
    >{{ msgMinLength }}</small
  >
  <small
    *ngIf="
      invalid &&
      ngControl?.control?.hasError('pattern') &&
      !ngControl?.control?.hasError('minlength')
    "
    id="require-help"
    class="p-error"
    >{{ msgPattern }}</small
  >
  <small
    *ngIf="
      invalid &&
      ngControl?.control?.hasError('maxlength') &&
      !ngControl?.control?.hasError('pattern')
    "
    id="require-help"
    class="p-error"
    >{{ msgMaxLength }}</small
  >

  <small
    *ngIf="
      useFormGroup &&
      invalidFormGroup &&
      templateMessage &&
      !ngControl?.control?.hasError('maxlength')
    "
    id="require-help"
    class="p-error"
  >
    <ng-template
      [ngIf]="messageErrorTemplate"
      [ngIfElse]="plainMessageTemplate"
    >
      <ng-container
        *ngTemplateOutlet="
          messageErrorTemplate;
          context: {
            $implicit: useFormGroup.errors,
            control: useFormGroup.controls
          }
        "
      ></ng-container>
    </ng-template>
    <ng-template #plainMessageTemplate>
      {{ msgError }}
    </ng-template>
  </small>
</div>
