import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import dayjs from 'dayjs';
import {
  ApplyLoan,
  ConfirmApplicationBody,
  CustomDimensions,
  DeeplinkBody,
  EventAppFlyer,
  EventGATagging,
} from '../../interfaces/js-interface.interface';
import { GA_EVENT_ACTION } from '../../constant/base.enum';
import { HttpService } from '@web-di-ld/apps/http';

export interface JavascriptCallback {
  openAppDeeplink(data: string): any;
  enterFullScreen(data: string): any;
  exitFullScreen(data: any): any;
  exitFullScreenToPageRating(): any;
  confirmApplication(data: string): any;
  gaTagging(data: any): any;
  appsflyerTagging(data: any): any;
  applyLoan(data: any): any;
  backToHome(): any;
  openWebUrl(data: string): any;
  openHiddenWebUrl(data: string): any;
  openDeeplink(deeplinkUrl: string): any;
  shareText(url: string): any;
}

declare const LoanWeb: JavascriptCallback;
declare const window: any;
declare const webkit: any;
@Injectable({
  providedIn: 'root',
})
export class JsInterfaceService {
  constructor(
    private router: Router,
    private location: Location,
    public httpService: HttpService
  ) {}

  environment = environment;
  public enterFullScreen(url?: string) {
    const urlCombine = `${environment.baseUrl}${url}`;
    console.log('enterFullScreen: ', urlCombine);
    const body = JSON.stringify({
      redirectUrl: urlCombine,
    });
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = LoanWeb;
        console.log('isAndroid');
        android.enterFullScreen(body);
      } else {
        window.webkit.messageHandlers.enterFullScreen.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
    } finally {
      this.router.navigate([url], {
        skipLocationChange: false,
      });
    }
  }

  public openDeepLink(data?: DeeplinkBody) {
    console.log('openAppDeeplink', data);
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;

    try {
      if (isAndroid) {
        const android = LoanWeb;
        console.log('isAndroid');
        android.openAppDeeplink(body);
      } else {
        window.webkit.messageHandlers.openAppDeeplink.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
    }
  }

  public openDeeplinkNext(deeplinkUrl: string) {
    const data = { deeplink: deeplinkUrl };
    console.log('openDeeplink: ', data);
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = LoanWeb;
        console.log('isAndroid');
        android.openDeeplink(body);
      } else {
        window.webkit.messageHandlers.openDeeplink.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
    }
  }

  public exitFullScreen(previousUrl?: string) {
    console.log('exitFullScreen');
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = LoanWeb;
        console.log('isAndroid');
        android.exitFullScreen(null);
      } else {
        window.webkit.messageHandlers.exitFullScreen.postMessage(null);
      }
    } catch (error) {
      console.log('isWeb');
    } finally {
      if (previousUrl) this.router.navigate([previousUrl]);
    }
  }

  public exitFullScreenToPageRating() {
    console.log('exitFullScreenToPageRating');
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = LoanWeb;
        console.log('isAndroid');
        android.exitFullScreenToPageRating();
      } else {
        window.webkit.messageHandlers.exitFullScreenToPageRating.postMessage(
          null
        );
      }
    } catch (error) {
      console.log('isWeb');
    } finally {
      this.location.back();
    }
  }

  public confirmApplication(data: ConfirmApplicationBody) {
    console.log('confirmApplication', data);
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = LoanWeb;
        console.log('isAndroid');
        android.confirmApplication(body);
      } else {
        window.webkit.messageHandlers.confirmApplication.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
      // handle when open on web
    }
  }

  private async transformDataLayerFromGaTaggingInterface(
    event: EventGATagging,
    customDimensions?: CustomDimensions[]
  ): Promise<Record<string, string | number | null>> {
    const productType =
      customDimensions?.find((dimension) => dimension.key === 'PRODUCT_TYPE')
        ?.value ?? null;
    const information =
      customDimensions?.find((dimension) => dimension.key === 'INFORMATION')
        ?.value ?? null;
    const uuid = await this.httpService.authorizationCode$.toPromise();
    const eventName =
      event.action === GA_EVENT_ACTION.VIEW ? 'screen_view' : 'track_event';
    return {
      event: eventName,
      event_name: eventName,
      screen_name: event.category || null,
      event_action: event.action === GA_EVENT_ACTION.VIEW ? null : event.action,
      event_category: event.category || null,
      event_label: event.label || null,
      event_value: event.value || null,
      product_type: productType,
      information: information,
      hcif: this.httpService.verifyToken?.data.cifNoEncrypt || null,
      UUID: uuid,
    };
  }

  public async gaTaggingWithDataLayer(
    event: EventGATagging,
    customDimensions?: CustomDimensions[]
  ): Promise<void> {
    const payload = await this.transformDataLayerFromGaTaggingInterface(
      event,
      customDimensions
    );
    const dataLayer = window.dataLayer;
    console.log('========== START GA TAGGING (Data Layer) ==========');
    console.log('Tagging At', new Date().toString());
    console.log('Payload =>', payload);

    try {
      dataLayer.push(payload);
      console.log('Status => Success');
    } catch (error) {
      console.log('Status => Fail');
      if (error instanceof Error) {
        console.log(`Error name => ${error.name}`);
        console.log(`Error message => ${error.message}`);
        console.log(`Error stack => ${error.stack}`);
      } else if (typeof error === 'string') {
        console.log(`Error string => ${error}`);
      } else {
        console.log(`Error => ${error}`);
      }
    } finally {
      console.log('=========== END GA TAGGING (Data Layer) ===========');
    }
  }

  public gaTagging(
    event: EventGATagging,
    customDimensions?: CustomDimensions[]
  ) {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    const data = customDimensions
      ? {
          event,
          customDimensions,
        }
      : { event };

    if (this.environment.mode === 'local') {
      return;
    }
    console.log('========== START GA TAGGING ==========');
    const body = JSON.stringify(data);

    console.log('Platform =>', isAndroid ? 'Android' : 'iOS');
    console.log('Tagging At', new Date().toString());
    console.log('Payload =>', data);
    try {
      if (isAndroid) {
        const android = LoanWeb;
        android.gaTagging(body);
      } else {
        window.webkit.messageHandlers.gaTagging.postMessage(body);
      }
      console.log('Status => Success');
    } catch (error: unknown) {
      // handle when open on web
      console.log('Status => Fail');
      if (error instanceof Error) {
        console.log(`Error name => ${error.name}`);
        console.log(`Error message => ${error.message}`);
        console.log(`Error stack => ${error.stack}`);
      } else if (typeof error === 'string') {
        console.log(`Error string => ${error}`);
      } else {
        console.log(`Error => ${error}`);
      }
    } finally {
      console.log('=========== END GA TAGGING ===========');
    }

    // TODO: Remove this blacklist after next fix ga tagging
    try {
      this.gaTaggingWithDataLayer(event, customDimensions);
    } catch (error: unknown) {
      console.log('Status => Fail');
      if (error instanceof Error) {
        console.log(`Error name => ${error.name}`);
        console.log(`Error message => ${error.message}`);
        console.log(`Error stack => ${error.stack}`);
      } else if (typeof error === 'string') {
        console.log(`Error string => ${error}`);
      } else {
        console.log(`Error => ${error}`);
      }
    }
  }

  public appsflyerTagging(eventName: string) {
    const data: EventAppFlyer = {
      eventName: eventName,
    };
    console.log('appsflyerTagging : ', data);

    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = LoanWeb;
        console.log('isAndroid');
        android.appsflyerTagging(body);
      } else {
        window.webkit.messageHandlers.appsflyerTagging.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
      // handle when open on web
    }
  }
  public appsflyerTaggingWithParam(eventName: string, eventValue: string) {
    const data: EventAppFlyer = {
      eventName: eventName,
      eventValue: eventValue,
    };
    console.log('appsflyerTaggingWithParam : ', data);

    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = LoanWeb;
        console.log('isAndroid');
        android.appsflyerTagging(body);
      } else {
        window.webkit.messageHandlers.appsflyerTagging.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
      // handle when open on web
    }
  }

  public applyLoan(data?: ApplyLoan) {
    console.log('applyLoan', data);
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;

    try {
      if (isAndroid) {
        const android = LoanWeb;
        console.log('isAndroid');
        android.applyLoan(body);
      } else {
        window.webkit.messageHandlers.applyLoan.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
    }
  }

  public backToHome() {
    console.log('backToHome');
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = LoanWeb;
        console.log('isAndroid');
        android.backToHome();
      } else {
        window.webkit.messageHandlers.backToHome.postMessage(null);
      }
    } catch (error) {
      console.log('isWeb');
    }
  }

  openExternalWebview(url: string) {
    const data = { webUrl: url };
    console.log('openExternalWebview: ', data);
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = LoanWeb;
        console.log('isAndroid');
        android.openWebUrl(body);
      } else {
        window.webkit.messageHandlers.openWebUrl.postMessage(body);
      }
    } catch (error) {
      console.log(error);
      console.log('isWeb');
    }
  }

  openHiddenWebUrl(url: string) {
    const data = { webUrl: url };
    console.log('openHiddenWebUrl: ', data);
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = LoanWeb;
        console.log('isAndroid');
        android.openHiddenWebUrl(body);
      } else {
        window.webkit.messageHandlers.openHiddenWebUrl.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
    }
  }

  shareText(url: string) {
    const data = { text: url };
    console.log('shareText: ', data);
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = LoanWeb;
        console.log('isAndroid');
        android.shareText(body);
      } else {
        window.webkit.messageHandlers.shareText.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
    }
  }

  public generateApplicationRef(systemRef: string, campaignID: string) {
    const crypto = window.crypto || window.msCrypto;
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);

    const year = dayjs().format('YY');
    const month = dayjs().format('MM');
    const date = dayjs().format('DD');
    const randomNumber = Math.floor(100000 + array[0] * 900000);
    return `${systemRef}${campaignID}${year}${month}${date}${randomNumber}`;
  }
}
