export const environment = {
  baseUrl: 'http://localhost:4200',
  nextApiBaseUrl: 'https://api-uat.newnext.krungthai.com',
  dglApiBaseUrl: 'https://apigw-dgl-lfm-uat.th-service.co.in',
  production: false,
  mode: 'uat',
  version: 'uat4.6.68',
  tagId: true,
  noCache: true,
  gtmId: 'GTM-K3GLLFPP',
};
