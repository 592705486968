import { StepService } from 'apps/di-ld/src/app/core/services/shared/step.service';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { pathUrl } from 'apps/di-ld/src/app/configs';
import { JsInterfaceService } from 'apps/di-ld/src/app/core/services/shared/js-interface.service';
import { AppStateService } from 'apps/di-ld/src/app/app-state.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  CustomDimensions,
  EventGATagging,
} from 'apps/di-ld/src/app/core/interfaces/js-interface.interface';
import { FLOW_STATE } from 'apps/di-ld/src/app/core/interfaces';
import { DraftService } from 'apps/di-ld/src/app/core/services';
import { Subscription } from 'rxjs';
import {
  GA_EVENT_ACTION,
  GA_EVENT_CATEGORY,
} from 'apps/di-ld/src/app/core/constant/base.enum';
import { AisService } from 'apps/di-ld/src/app/core/services/ais/ais.service';

declare const window: any;
@Component({
  selector: 'web-di-ld-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title!: string;
  @Input() url!: string;
  @Input() share!: string;
  navigator = window.navigator as any;
  subscription = new Subscription();
  public productType!: string;

  constructor(
    private router: Router,
    private zone: NgZone,
    private location: Location,
    private jsInterfaceService: JsInterfaceService,
    private appState: AppStateService,
    private draftService: DraftService,
    private stepService: StepService,
    private aisService: AisService
  ) {
    const _this = this;
    window.addEventListener('popstate', function (event: any) {
      event.preventDefault();
      _this.zone.run(() => {
        const currentUrl = _this.location.path();
        const previousUrl = _this.previousUrl(currentUrl);
        const url = _this.router.url;
        const {
          flow,
          enterFlow,
          state,
          isTopup,
        } = _this.appState.snapshot.flow;
        console.log(`popstate: header ${event}, ${flow}`);
        console.log('🚀 ~ currentUrl:', currentUrl);
        console.log('🚀 ~ currentUrl(router):', url);
        console.log('🚀 ~ _this.prevUrl:', previousUrl);

        console.log(
          `🚥 ~ flow: ${flow}, enterFlow: ${enterFlow}, state: ${state}`
        );

        if (!previousUrl) {
          _this.router.navigateByUrl(pathUrl.loanProductShelf, {
            replaceUrl: true,
          });
        } else if (
          currentUrl === pathUrl.aisOtp &&
          previousUrl === pathUrl.aisKtbTermAndCon
        ) {
          setTimeout(() => {
            _this.router.navigateByUrl(pathUrl.ktbTermAndCon, {
              replaceUrl: true,
            });
          }, 0); // _this.router.navigateByUrl(pathUrl.ktbTermAndCon, { replaceUrl: true });
          return false;
        } else if (
          currentUrl === pathUrl.aisConsent &&
          previousUrl === pathUrl.aisKtbTermAndCon
        ) {
          // back on device from highlight to product shelf/loan calculation
          if (flow === FLOW_STATE.CALCULATE) {
            // _this.router.navigateByUrl(pathUrl.loanApply.calculation, { replaceUrl: true });
            // _this.navigateTo(pathUrl.loanApply.calculation);
          } else {
            // window.location.replace(pathUrl.loanProductShelf);
            setTimeout(() =>
              _this.jsInterfaceService.exitFullScreen(pathUrl.loanProductShelf)
            );
          }
          return false;
        } else if (
          url === pathUrl.loanApply.calculation &&
          currentUrl === pathUrl.aisKtbTermAndCon &&
          previousUrl === pathUrl.loanApply.calculation
        ) {
          setTimeout(() =>
            _this.jsInterfaceService.exitFullScreen(pathUrl.loanProductShelf)
          );
        }
        return false;
      });
      return true;
    });
  }

  ngOnInit(): void {
    this.setProductType();
  }

  setProductType() {
    this.subscription.add(
      this.appState.ProductDetail$.pipe(
        distinctUntilChanged(),
        debounceTime(400)
      ).subscribe((productConfig) => {
        this.productType = productConfig.productCode;
      })
    );
  }

  previousUrl(path: string) {
    const { flow, enterFlow, state, isTopup } = this.appState.snapshot.flow;
    const returnPath = this.getPath(path, flow, enterFlow, isTopup);
    if (returnPath === path) {
      return this.getPath(path);
    } else {
      return returnPath;
    }
  }

  getPath(
    path: string,
    flow?: FLOW_STATE,
    enterFlow?: FLOW_STATE,
    isTopup?: boolean
  ) {
    switch (path) {
      case pathUrl.loanApply.apply:
        return flow === FLOW_STATE.COMFIRM
          ? pathUrl.confirmingDetails
          : enterFlow === FLOW_STATE.CALCULATE
          ? pathUrl.loanApply.calculation
          : pathUrl.loanProductShelf;
      case pathUrl.loanApply.topupDetail:
        return flow === FLOW_STATE.TOPUP_CONFIRM
          ? pathUrl.loanApply.topupConfirm
          : enterFlow === FLOW_STATE.CALCULATE
          ? pathUrl.loanApply.calculation
          : pathUrl.loanProductShelf;
      case pathUrl.loanApply.productDetail: {
        if (flow === FLOW_STATE.TOPUP_DETAIL) {
          return pathUrl.loanApply.topupDetail;
        } else if (flow === FLOW_STATE.TOPUP_REQUEST_CREDIT) {
          return pathUrl.loanApply.topupRequestCredit;
        } else {
          return pathUrl.loanApply.apply;
        }
      }
      case pathUrl.loanApply.calculation:
        return pathUrl.loanProductShelf;
      case pathUrl.personalInformation:
        return flow === FLOW_STATE.COMFIRM
          ? pathUrl.confirmingDetails
          : pathUrl.loanApply.apply;
      case pathUrl.additionalInformation:
        return flow === FLOW_STATE.COMFIRM
          ? pathUrl.confirmingDetails
          : pathUrl.personalInformation;
      case pathUrl.editInformation:
        return isTopup
          ? pathUrl.loanApply.topupConfirm
          : pathUrl.personalInformation;
      case pathUrl.confirmingDetails:
        return flow === FLOW_STATE.COMFIRM
          ? pathUrl.confirmingDetails
          : pathUrl.additionalInformation;
      case pathUrl.marketingConsent:
        return isTopup
          ? pathUrl.loanApply.topupConfirm
          : pathUrl.confirmingDetails;
      case pathUrl.ncbConsent:
        return pathUrl.marketingConsent;
      case pathUrl.aisKtbTermAndCon:
        if (flow === FLOW_STATE.CALCULATE) {
          return pathUrl.loanApply.calculation;
        } else {
          return pathUrl.loanProductShelf;
        }
      case pathUrl.aisOtp:
        return pathUrl.aisKtbTermAndCon;
      case pathUrl.aisConsent:
        return pathUrl.aisKtbTermAndCon;
      case pathUrl.confirmSummary:
        return pathUrl.confirmSummary;
      case pathUrl.promotion:
        return pathUrl.loanProductShelf;
      case pathUrl.loanApply.topup:
        return pathUrl.loanProductShelf;
      case pathUrl.loanApply.topupRequestCredit:
        return pathUrl.loanProductShelf;
      case pathUrl.loanApply.topupAdditional: {
        if (flow === FLOW_STATE.TOPUP_CONFIRM) {
          return pathUrl.loanApply.topupConfirm;
        } else {
          if (
            this.stepService.items[0].flow === FLOW_STATE.TOPUP_REQUEST_CREDIT
          ) {
            return pathUrl.loanApply.topupRequestCredit;
          } else {
            return pathUrl.loanApply.topupDetail;
          }
        }
      }
      case pathUrl.loanApply.topupConfirm:
        return pathUrl.loanApply.topupAdditional;
      case pathUrl.editMailingAddress:
        return isTopup ? pathUrl.loanApply.topup : pathUrl.personalInformation;
      default:
        return '';
    }
  }

  getPageEvent(): GA_EVENT_CATEGORY | string {
    const currentUrl = this.location.path();
    switch (currentUrl) {
      case pathUrl.loanApply.apply:
        return GA_EVENT_CATEGORY.LOAN_APPLY;
      case pathUrl.loanApply.productDetail:
        return GA_EVENT_CATEGORY.PRODUCT_DETAIL;
      case pathUrl.loanApply.calculation:
        return GA_EVENT_CATEGORY.LOAN_CALCULATE;
      case pathUrl.personalInformation:
        return GA_EVENT_CATEGORY.PERSONAL_INFORMATION;
      case pathUrl.additionalInformation:
        return GA_EVENT_CATEGORY.ADDITIONAL_INFORMATION;
      case pathUrl.editInformation:
        return GA_EVENT_CATEGORY.EDIT_INFORMATION;
      case pathUrl.confirmingDetails:
        return GA_EVENT_CATEGORY.CONFIRM_DETAIL;
      case pathUrl.marketingConsent:
        return GA_EVENT_CATEGORY.MARKETING_CONSENT;
      case pathUrl.ncbConsent:
        return GA_EVENT_CATEGORY.NCB_CONSENT;
      case pathUrl.promotion:
        return GA_EVENT_CATEGORY.PROMOTION;
      case pathUrl.loanApply.topupDetail:
        return GA_EVENT_CATEGORY.TOPUP_LOAN_DETAIL;
      case pathUrl.loanApply.topupAdditional:
        return GA_EVENT_CATEGORY.TOPUP_ADDITIONAL_DETAIL;
      case pathUrl.loanApply.topupConfirm:
        return GA_EVENT_CATEGORY.TOPUP_CONFIRMATION;
      case pathUrl.editMailingAddress:
        return GA_EVENT_CATEGORY.EDIT_MAILING_ADDRESS;
      case pathUrl.loanApply.topupRequestCredit:
        return GA_EVENT_CATEGORY.TOPUP_REQUEST_CREDIT;
      case pathUrl.aisKtbTermAndCon:
        return GA_EVENT_CATEGORY.AIS_T_AND_C;
      case pathUrl.aisOtp:
        return GA_EVENT_CATEGORY.AIS_OTP;
      case pathUrl.aisConsent:
        return GA_EVENT_CATEGORY.AIS_CONSENT;
      default:
        return '';
    }
  }

  private getProductType(): string {
    const currentUrl = this.location.path();
    switch (currentUrl) {
      case pathUrl.aisKtbTermAndCon:
      case pathUrl.aisOtp:
      case pathUrl.aisConsent:
        return this.appState.snapshot.loanProduct.productCode;
      default:
        return this.productType;
    }
  }

  private getAdditionalDimensions(): CustomDimensions[] {
    const currentUrl = this.location.path();
    switch (currentUrl) {
      case pathUrl.aisOtp:
        return [this.aisService.informationDimension];
      default:
        return [];
    }
  }

  async navigateTo(url: string = '') {
    const currentUrl = this.location.path();
    const previousUrl = this.previousUrl(currentUrl);
    this.draftService.backEvtSubject.next();
    const pageEvent = this.getPageEvent();
    const event: EventGATagging = {
      action: GA_EVENT_ACTION.CLICK,
      category: pageEvent,
      label: `${pageEvent}_Back`,
      value: null,
    };
    const additionalDimensions = this.getAdditionalDimensions();
    const dimension: CustomDimensions[] = [
      {
        key: 'PRODUCT_TYPE',
        value: this.getProductType(),
      },
      ...additionalDimensions,
    ];
    if (
      currentUrl === pathUrl.loanApply.calculation ||
      currentUrl === pathUrl.promotion
    ) {
      this.gaTagging(event);
    } else {
      this.gaTagging(event, dimension);
    }

    const { flow, enterFlow, state } = this.appState.snapshot.flow;
    console.log(`navigateTo: ${flow}, ${state}`);
    console.log(
      `navigateTo(snapshot): ${this.appState.snapshot.draft}, ${this.appState.snapshot.draft?.loanInfo}`
    );
    if (
      this.appState.snapshot.draft &&
      this.appState.snapshot.draft.loanInfo &&
      flow !== FLOW_STATE.COMFIRM &&
      flow !== FLOW_STATE.SHELF &&
      flow !== FLOW_STATE.TOPUP_DETAIL &&
      flow !== FLOW_STATE.TOPUP_CONFIRM &&
      flow !== FLOW_STATE.CLEAR &&
      state !== FLOW_STATE.SALESHEET &&
      state !== FLOW_STATE.CALCULATE
    ) {
      this.draftService.saveDraft();
    }

    console.log('🚀 ~ previousUrl, currentUrl:', previousUrl, currentUrl);
    if (
      (currentUrl === pathUrl.loanApply.topupRequestCredit ||
        currentUrl === pathUrl.loanApply.apply ||
        currentUrl === pathUrl.loanApply.topupDetail ||
        currentUrl === pathUrl.loanApply.calculation ||
        currentUrl === pathUrl.promotion) &&
      previousUrl !== pathUrl.confirmingDetails &&
      previousUrl !== pathUrl.loanApply.calculation &&
      flow !== FLOW_STATE.RATING
    ) {
      this.jsInterfaceService.exitFullScreen(previousUrl);
    } else if (flow === FLOW_STATE.RATING) {
      this.jsInterfaceService.exitFullScreenToPageRating();
    } else {
      previousUrl ? this.router.navigate([previousUrl]) : this.location.back();
    }
  }

  close() {
    this.router.navigate(['/loan-product-shelf']);
  }

  gaTagging(event: EventGATagging, dimension?: CustomDimensions[]) {
    this.jsInterfaceService.gaTagging(event, dimension);
  }

  navigatorShare() {
    const promotion = this.appState.snapshot.promotion;
    this.jsInterfaceService.shareText(promotion.urlToShare);
  }

  ngOnChanges(): void {
    this.setProductType();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
